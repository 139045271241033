"use client";

import { useRouter,usePathname } from 'next/navigation';
import { useLocale } from "next-intl";

export default function LanguageSwitcher() {

    useRouter();
    useLocale();
    usePathname();

    const router = useRouter();
    const locale = useLocale();
    const pathName = usePathname();
    
    const handleLocaleChange = (locale) => {
        const path = pathName;
        let newPath = null;
        if(locale === "en"){
            newPath = locale === 'en' ? path.replace("/tr", '/en/') : `/${path}`;
        }
        else{
            newPath = locale === 'tr' ? path.replace("/", '/tr/') : `/${path}`;
        }
        router.push(newPath, newPath, { locale });
    };

    return (
        <>
            <label htmlFor="lang_switcher" className="sr-only">Lang</label>
            <select
                id="lang_switcher"
                name="lang_switcher"
                value={locale}
                onChange={(e) => handleLocaleChange(e.target.value)}
                className="text-[13px] bg-transparent text-[#ababab] hover:text-white"
            >
                <option value="en">EN</option>
                <option value="tr">TR</option>
            </select>
        </>
    );
};